import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import type { Form } from "src/types/form";
import type { ErrorType } from "src/types/error";
import { useAxios } from "../use-axios";

type PublishFormRequest = {
  payload: {
    formBuilderJson: string | null;
  };
  isPublished: boolean;
};

export const usePublishForm = (id?: string) => {
  const { patch } = useAxios();

  return useMutation<Form, AxiosError<ErrorType>, PublishFormRequest>(
    async ({ isPublished, payload }: PublishFormRequest) =>
      await patch<PublishFormRequest["payload"], Form>(
        `/forms/publish/${id}`,
        { formBuilderJson: payload.formBuilderJson },
        {
          params: {
            isPublished,
          },
        },
      ),
  );
};
