import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";

type QueryPagesResponse = {
  page: string;
  questionTitle: string;
  questionNo: string;
}[];

export const useQueryTitles = (formId: string) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["submission-titles", formId],
    queryFn: async () => {
      return await get<QueryPagesResponse>("/data/question/titles", {
        params: { formId },
      });
    },
    staleTime: config.staleTime,
  });
};
