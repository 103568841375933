import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import type { ErrorType } from 'src/types/error';
import { useAxios } from '../use-axios';
import { Form } from 'src/types/form';

type CreateFormsRequestPayload = {
  formName: string;
  formCategory: string;
  description: string;
  labId?: string;
};

export const useCreateForm = () => {
  const { post } = useAxios();

  return useMutation<Form, AxiosError<ErrorType>, CreateFormsRequestPayload>(
    async (payload: CreateFormsRequestPayload) =>
      await post<CreateFormsRequestPayload, Form>('/forms/create', payload),
  );
};
