import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import type { ErrorType } from "src/types/error";
import { User } from "src/types/user";
import { useAxios } from "../use-axios";

type AuthUserPayload = {
  token: string;
};

type AuthUserResponse = {
  labData: User;
  userData: {
    userName: string;
    userEmail: string;
  };
};

export const useAuthUser = () => {
  const { post } = useAxios();

  return useMutation<AuthUserResponse, AxiosError<ErrorType>, AuthUserPayload>(
    async (payload: AuthUserPayload) =>
      await post<AuthUserPayload, AuthUserResponse>("/auth/me", payload),
  );
};
