import type { ReactNode } from "react";
import { useMemo } from "react";
import { SvgIcon } from "@mui/material";
import AnalyticsIcon from "@mui/icons-material/AnalyticsOutlined";

import Users03Icon from "src/icons/untitled-ui/duocolor/file-01";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import LayoutAlt02Icon from "src/icons/untitled-ui/duocolor/layout-alt-02";
import GraduationHat01 from "src/icons/untitled-ui/duocolor/graduation-hat-01";
import { paths } from "src/paths";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  return useMemo(() => {
    return [
      {
        items: [
          {
            title: "Dashboard",
            path: paths.dashboard.index,
            roles: ["SUPER_ADMIN", "LAB_ADMIN"],
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
          {
            title: "Domains",
            path: paths.dashboard.domain.index,
            roles: ["SUPER_ADMIN"],
            icon: (
              <SvgIcon fontSize="small">
                <GraduationHat01 />
              </SvgIcon>
            ),
            items: [
              {
                title: "List",
                path: paths.dashboard.domain.index,
              },
              {
                title: "Create",
                path: paths.dashboard.domain.create,
              },
            ],
          },
          {
            title: "Forms",
            path: paths.dashboard.forms.index,
            roles: ["SUPER_ADMIN", "LAB_ADMIN"],
            icon: (
              <SvgIcon fontSize="small">
                <LayoutAlt02Icon />
              </SvgIcon>
            ),
            items: [
              {
                title: "List",
                path: paths.dashboard.forms.index,
              },
              {
                title: "Create",
                path: paths.dashboard.forms.create,
              },
            ],
          },
          {
            title: "Labs",
            path: paths.dashboard.labs.index,
            roles: ["SUPER_ADMIN"],
            icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
            ),
            items: [
              {
                title: "List",
                path: paths.dashboard.labs.index,
              },
              {
                title: "Create",
                path: paths.dashboard.labs.create,
              },
            ],
          },
          {
            title: "Submissions",
            path: paths.dashboard.submissions.index,
            roles: ["SUPER_ADMIN", "LAB_ADMIN"],
            icon: (
              <SvgIcon fontSize="small">
                <AnalyticsIcon />
              </SvgIcon>
            ),
            items: [
              {
                title: "List",
                path: paths.dashboard.submissions.index,
              },
            ],
          },
        ],
      },
    ];
  }, []);
};
