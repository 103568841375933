import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";

import { useSettings } from "src/hooks/use-settings";
import { useSections } from "./config";
import { HorizontalLayout } from "./horizontal-layout";
import { VerticalLayout } from "./vertical-layout";
import { useAuth } from "src/hooks";
import { withAuthGuard } from "src/hoc/with-auth-guard";

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
  const settings = useSettings();
  const sections = useSections();
  const { user } = useAuth();
  const userRole = user?.labData?.userRole;

  const filteredSections = sections
    .map((section) => ({
      ...section,
      items: section.items.filter(
        (item) => !item.roles || item.roles.includes(userRole!),
      ),
    }))
    .filter((section) => section.items.length > 0);

  if (settings.layout === "horizontal") {
    return (
      <HorizontalLayout
        sections={filteredSections}
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      sections={filteredSections}
      navColor={settings.navColor}
      {...props}
    />
  );
});

Layout.propTypes = {
  children: PropTypes.node,
};
