import { useQuery } from '@tanstack/react-query';

import { useAxios } from '../use-axios';
import type { Form } from 'src/types/form';
import config from '../config';

export const useQueryForm = (formId?: string | null) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ['form', formId],
    queryFn: async () => {
      return await get<Form>(`/forms/form/${formId}`);
    },
    staleTime: config.staleTime,
  });
};
