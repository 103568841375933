import * as SurveyCore from 'survey-core';
import * as widgets from 'surveyjs-widgets';
import $ from 'jquery';
import 'jquery-bar-rating/jquery.barrating.js';

window['$'] = window['jQuery'] = $;
require('jquery-ui-dist/jquery-ui.js');

export const registerBarRating = () => {
  widgets.jquerybarrating(SurveyCore, $);
};
