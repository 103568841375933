import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import type { Lab } from 'src/types/lab';
import type { ErrorType } from 'src/types/error';
import { useAxios } from '../use-axios';

type UpdateLabRequestPayload = {
  labName: string;
  email: string;
  description: string;
};

export const useUpdateLab = (id?: string) => {
  const { patch } = useAxios();

  return useMutation<Lab, AxiosError<ErrorType>, UpdateLabRequestPayload>(
    async (payload: UpdateLabRequestPayload) =>
      await patch<UpdateLabRequestPayload, Lab>(`/labs/update/${id}`, payload),
  );
};
