import { FormSubmission } from 'src/sections/dashboard/form/form-submission';
import { useQueryForm, useSearchParams } from 'src/hooks';
import { Seo } from 'src/components/seo';

const Page = () => {
  const queryParams = useSearchParams();
  const formId = queryParams.get('formId');
  const { data: formData } = useQueryForm(formId);

  if (!formData) {
    return null;
  }

  return (
    <>
      <Seo title="Form submission" />
      <FormSubmission formJsonData={formData.formBuilderJson} />
    </>
  );
};

export default Page;
