import { lazy, Suspense } from "react";
import { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";

import { Layout as DashboardLayout } from "src/layouts/dashboard";
import { DomainGuard } from "src/guard/domain-guard";

const IndexPage = lazy(() => import("src/pages/index"));

// Labs
const LabListPage = lazy(() => import("src/pages/dashboard/lab/list"));
const LabCreatePage = lazy(() => import("src/pages/dashboard/lab/create"));
const LabEditPage = lazy(() => import("src/pages/dashboard/lab/edit"));

// Allowed Domains
const DomainListPage = lazy(() => import("src/pages/dashboard/domain/list"));
const DomainCreatePage = lazy(
  () => import("src/pages/dashboard/domain/create"),
);

// Forms
const FormListPage = lazy(() => import("src/pages/dashboard/form/list"));
const FormCreatePage = lazy(() => import("src/pages/dashboard/form/create"));
const FormClonePage = lazy(() => import("src/pages/dashboard/form/clone"));
const FormEditPage = lazy(() => import("src/pages/dashboard/form/edit"));
const SurveyCreatorWidgetPage = lazy(
  () => import("src/pages/dashboard/form/design"),
);
const FormPreviewPage = lazy(() => import("src/pages/dashboard/form/preview"));
const FormSubmissionListPage = lazy(
  () => import("src/pages/dashboard/form/submission-list"),
);
const FormSubmissionPreviewPage = lazy(
  () => import("src/pages/dashboard/form/results"),
);
const FormResultsPreviewPage = lazy(
  () => import("src/pages/dashboard/form/results"),
);

// Submission
const SubmissionListPage = lazy(
  () => import("src/pages/dashboard/submission/list"),
);
const QuestionListDataPage = lazy(
  () => import("src/pages/dashboard/submission/questions"),
);

export const dashboardRoutes: RouteObject[] = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "domain",
        children: [
          {
            index: true,
            element: <DomainListPage />,
          },
          {
            path: "create",
            element: <DomainCreatePage />,
          },
        ],
      },
      {
        path: "lab",
        children: [
          {
            index: true,
            element: <LabListPage />,
          },
          {
            path: "create",
            element: <LabCreatePage />,
          },
          {
            path: ":labId/edit",
            element: <LabEditPage />,
          },
        ],
      },
      {
        path: "form",
        children: [
          {
            index: true,
            element: <FormListPage />,
          },
          {
            path: "create",
            element: <FormCreatePage />,
          },
          {
            path: ":formId/clone",
            element: <FormClonePage />,
          },
          {
            path: ":formId/submissions/list",
            element: <FormSubmissionListPage />,
          },
          {
            path: "submissions/:submissionId/preview",
            element: <FormSubmissionPreviewPage />,
          },
          {
            path: ":formId/results",
            element: <FormResultsPreviewPage />,
          },
          {
            path: ":formId/edit",
            element: <FormEditPage />,
          },
          {
            path: ":formId/design",
            element: <SurveyCreatorWidgetPage />,
          },
          {
            path: ":formId/preview",
            element: <FormPreviewPage />,
          },
        ],
      },
      {
        path: "submission",
        children: [
          {
            index: true,
            element: <SubmissionListPage />,
          },
          {
            path: ":formId/questionsData",
            element: <QuestionListDataPage />,
          },
        ],
      },
    ],
  },
];
