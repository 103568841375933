import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAxios } from '../use-axios';
import { ErrorType } from 'src/types/error';

type SubmitFormPayload = {
  formId: string;
  userCode: string;
  formData: string;
};

export const useSubmitForm = () => {
  const { post } = useAxios();

  return useMutation<Partial<ErrorType>, AxiosError<ErrorType>, SubmitFormPayload>(
    async (payload: SubmitFormPayload) =>
      await post<SubmitFormPayload, Partial<ErrorType>>('/data/submit', payload),
  );
};
