import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import type { ErrorType } from 'src/types/error';
import { useAxios } from '../use-axios';

type ValidateFormIdPayload = {
  formId?: string;
};

export const useValidateFormId = () => {
  const { post } = useAxios();

  return useMutation<boolean, AxiosError<ErrorType>, ValidateFormIdPayload>(
    async (payload: ValidateFormIdPayload) =>
      await post<ValidateFormIdPayload, boolean>('/auth/form', payload),
  );
};
