import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAxios } from '../use-axios';
import type { ErrorType } from 'src/types/error';

type DeleteFormRequest = {};

type DeleteFormResponse = {};

export const useDeleteForm = (formId?: string) => {
  const { post } = useAxios();

  return useMutation<DeleteFormResponse, AxiosError<ErrorType>, DeleteFormRequest>(
    async () => await post<DeleteFormRequest, DeleteFormResponse>(`/forms/delete/${formId}`),
  );
};
