import { useMutation } from "@tanstack/react-query";

import { useAxios } from "../use-axios";

export const useExportLabs = () => {
  const { post } = useAxios();

  return useMutation(
    async () =>
      await post("/labs/export", {}, { responseType: "blob" }).then(
        (response: any) => {
          const href = URL.createObjectURL(response);
          const link = document.createElement("a");
          const fileName = "lab-list";
          link.href = href;
          link.setAttribute("download", `${fileName}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        },
      ),
  );
};
