import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAxios } from '../use-axios';
import type { ErrorType } from 'src/types/error';

type DeleteLabRequest = {
  deleteIds: string[];
};

type DeleteLabsResponse = {};

export const useDeleteLab = () => {
  const { post } = useAxios();

  return useMutation<DeleteLabsResponse, AxiosError<ErrorType>, DeleteLabRequest>(
    async (payload: DeleteLabRequest) =>
      await post<DeleteLabRequest, DeleteLabsResponse>('/labs/delete', payload),
  );
};
