import type { FC } from "react";
import PropTypes from "prop-types";

import User03Icon from "@untitled-ui/icons-react/build/esm/User03";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

import { useAuth } from "src/hooks";
import { convertUserRole } from "src/utils/convert-user-role";

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const { user } = useAuth();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">
          {user?.userData?.userName.toUpperCase()}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.userData?.userEmail}
        </Typography>
      </Box>
      <Divider />

      <Box sx={{ p: 1 }}>
        <ListItemButton
          component="a"
          href="#"
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <User03Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {convertUserRole(user?.labData?.userRole)}
              </Typography>
            }
          />
        </ListItemButton>
      </Box>

      <Divider sx={{ my: "0 !important" }} />
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
