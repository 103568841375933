import { useQuery } from "@tanstack/react-query";
import qs from "qs";

import { useAxios } from "../use-axios";
import config from "../config";
import { QuestionDataSearchState } from "src/pages/dashboard/submission/questions";

type GetQuestionDataResponse = {
  data: any;
  count: number;
  currentPage: number;
  totalPage: number;
  headers: string[];
  pageTitle?: string;
};

export const useQueryQuestionData = (
  request: QuestionDataSearchState,
  formId?: string,
  action?: string | null,
  searchedQuestion?: string | null,
  pageName?: string | null,
) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["submission-data", request, action, pageName],
    queryFn: async () => {
      const params = {
        size: request.rowsPerPage,
        page: request.page + 1,
        pageName,
        formId,
        ...(action === "search" && { question: searchedQuestion }),
      };

      const queryString = qs.stringify(params, { arrayFormat: "repeat" });
      const url = `/data/question/data?${queryString}`;

      return await get<GetQuestionDataResponse>(url);
    },
    staleTime: config.staleTime,
  });
};
