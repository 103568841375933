import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import type { ErrorType } from 'src/types/error';
import { useAxios } from '../use-axios';

type SignInPayload = {
  labName: string;
  labKey: string;
};

type SignInResponse = {
  accessToken: string;
};

export const useLogin = () => {
  const { post } = useAxios();

  return useMutation<SignInResponse, AxiosError<ErrorType>, SignInPayload>(
    async (payload: SignInPayload) =>
      await post<SignInPayload, SignInResponse>('/auth/signIn', payload),
  );
};
