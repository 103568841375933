import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";

type OverviewResponse = {
  formCounts: number;
  submissionCounts: number;
};

export const useQueryOverview = (token?: string | null) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["summary", token],
    queryFn: async () => {
      return await get<OverviewResponse>("/forms/summary", {
        params: { token },
      });
    },
    staleTime: config.staleTime,
  });
};
