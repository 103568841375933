import prodConfig from './production.json';
import stageConfig from './staging.json';

interface Config {
  apiKey: string;
  apiBaseUrl: string;
  environment: string;
  surveyJSlicenseKey: string;
}

let config: Config;

switch (process.env.REACT_APP_ENV) {
  case 'production':
    config = prodConfig;
    break;
  case 'stage':
    config = stageConfig;
    break;
  default:
    config = stageConfig;
    break;
}

export default config;
