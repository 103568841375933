import type { RouteObject } from "react-router";
import { Navigate } from "react-router-dom";

import { dashboardRoutes } from "./dashboard";
import { authRoutes } from "./auth";
import FormSubmissionPage from "src/pages/submission";
import CompletePage from "src/pages/complete";
import Error404Page from "src/pages/404";
import Error401Page from "src/pages/401";
import { FormIdGuard } from "src/guard/formId-guard";
import { UserIdGuard } from "src/guard/userId-guard";

export const routes: RouteObject[] = [
  ...dashboardRoutes,
  ...authRoutes,
  {
    path: "form/fill",
    element: (
      <FormIdGuard>
        <UserIdGuard>
          <FormSubmissionPage />
        </UserIdGuard>
      </FormIdGuard>
    ),
  },
  {
    path: "submit/complete",
    element: <CompletePage />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "*",
    element: <Navigate to="/401" replace={true} />,
  },
];
