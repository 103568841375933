import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import { Layout as AuthLayout } from 'src/layouts/auth/layout';
import { lazy } from 'react';

const LoginPage = lazy(() => import('src/pages/authentication/login'));

export const authRoutes: RouteObject[] = [
  {
    path: 'auth',
    element: (
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    ),
    children: [
      {
        path: 'signIn',
        element: <LoginPage />,
      },
    ],
  },
];
