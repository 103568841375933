import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import type { ErrorType } from "src/types/error";
import { useAxios } from "../use-axios";

type CreateDomainRequestPayload = {
  domain: string;
  labToken: string;
};

type CreateDomainResponse = {
  code: number;
  message: string;
};

export const useCreateDomain = () => {
  const { post } = useAxios();

  return useMutation<
    CreateDomainResponse,
    AxiosError<ErrorType>,
    CreateDomainRequestPayload
  >(
    async (payload: CreateDomainRequestPayload) =>
      await post<CreateDomainRequestPayload, CreateDomainResponse>(
        "/domain/create",
        payload,
      ),
  );
};
