import type { Lab } from 'src/types/lab';
import { useQuery } from '@tanstack/react-query';

import { useAxios } from '../use-axios';
import config from '../config';

type GetLabsRequest = {
  filters?: {
    query?: string;
    isActiveLab?: boolean;
  };
  page?: number;
  rowsPerPage?: number;
  sortBy?: string;
  sortDir?: 'asc' | 'desc';
};

type GetLabsResponse = {
  data: Lab[];
  count: number;
  currentPage: number;
  totalPage: number;
};

export const useQueryLabs = (request: GetLabsRequest) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ['lab-query', request],
    queryFn: async () => {
      const params = {
        keyword: request.filters?.query,
        size: request.rowsPerPage,
        page: request.page,
        sortBy: request.sortBy,
        sortDir: request.sortDir,
      };

      const data = await get<GetLabsResponse>('/labs/lists', { params });

      return data;
    },
    staleTime: config.staleTime,
  });
};
