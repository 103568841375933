import { createElement } from "react";
import { ElementFactory, Question, Serializer, settings } from "survey-core";
import { localization } from "survey-creator-react";
import { Divider } from "@mui/material";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";
import { FlatQuestion, FlatRepository, PdfBrick } from "survey-pdf";

import { SettingsType } from "src/types/form";

const CUSTOM_TYPE = "divider";

// A model for the new element
export class DividerModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

// Register `QuestionDividerModel` as a model for the `divider` type
export function registerDivider() {
  ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
    return new DividerModel(name);
  });
}

// Specify display names for the divider
const locale = localization.getLocale("");
locale.qt[CUSTOM_TYPE] = "Divider";

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [],
  () => {
    return new DividerModel("");
  },
  "question",
);
// Change default values for inherited properties
Serializer.getProperty(CUSTOM_TYPE, "hideNumber").defaultValue = true;
Serializer.getProperty(CUSTOM_TYPE, "titleLocation").defaultValue = "hidden";

// Use the Text icon for the Descriptive Text question type
(settings as SettingsType).customIcons["icon-divider"] = "icon-collapsedetail";

export class FCDivider extends SurveyQuestionElementBase {
  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  get type() {
    return this.question.colorPickerType;
  }

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue("readOnly") ||
      this.question.isDesignMode
      ? { pointerEvents: "none" }
      : undefined;
  }

  renderElement() {
    return (
      <Divider
        sx={{
          borderColor: "#6C737F",
        }}
      />
    );
  }
}

// Register `FCDivider` as a class that renders divider
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return createElement(FCDivider, props);
});

// Extend the PdfBrick to create a CustomPdfDividerBrick
class CustomPdfDividerBrick extends PdfBrick {
  async renderInteractive() {
    const doc = this.controller.doc;
    let oldFillColor = doc.getFillColor();

    // Set the fill color for the divider, could be a static color or based on some property
    doc.setFillColor("#F2F4F7"); // Setting a neutral color for the divider
    // Draw a rectangle across the width of the page to act as a divider
    doc.rect(this.xLeft, this.yTop, this.width, this.height, "F");

    // Restore the original fill color
    doc.setFillColor(oldFillColor);
  }
}

class FlatCustomDivider extends FlatQuestion {
  async generateFlatsContent(point: any) {
    // Calculate the dimensions of the divider
    // Assuming the divider should stretch across the width of the page
    const rect = { ...point };
    rect.yBot = point.yTop + 1; // Set the desired thickness of the divider
    rect.xRight =
      point.xLeft +
      this.controller.paperWidth -
      (this.controller.margins?.left || 0) -
      (this.controller.margins?.right || 0); // Make the divider span the page width

    // Return an array containing the custom PdfDividerBrick
    return [new CustomPdfDividerBrick(this.question, this.controller, rect)];
  }
}

// Register the custom divider for the 'divider' type
FlatRepository.getInstance().register(CUSTOM_TYPE, FlatCustomDivider);
