import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import type { ErrorType } from "src/types/error";
import { useAxios } from "../use-axios";

type AuthDomainPayload = {
  labToken: string;
  domain: string;
  userId: string;
  labKey: string;
};

type AuthDomainResponse = {
  name: string;
  email: string;
  jwtToken: string;
};

export const useAuthDomain = () => {
  const { post } = useAxios();

  return useMutation<
    AuthDomainResponse,
    AxiosError<ErrorType>,
    AuthDomainPayload
  >(
    async (payload: AuthDomainPayload) =>
      await post<AuthDomainPayload, AuthDomainResponse>(
        "/domain/auth",
        payload,
      ),
  );
};
