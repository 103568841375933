import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import type { Form } from 'src/types/form';
import type { ErrorType } from 'src/types/error';
import { useAxios } from '../use-axios';

type UpdateFormRequestPayload = {
  formName?: string;
  formCategory?: string;
  description?: string;
  formBuilderJson?: string;
};

export const useUpdateForm = (id?: string) => {
  const { patch } = useAxios();

  return useMutation<Form, AxiosError<ErrorType>, UpdateFormRequestPayload>(
    async (payload: UpdateFormRequestPayload) =>
      await patch<UpdateFormRequestPayload, Form>(`/forms/update/${id}`, payload),
  );
};
