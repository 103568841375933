import type { Lab } from 'src/types/lab';
import { useQuery } from '@tanstack/react-query';

import { useAxios } from '../use-axios';
import config from '../config';

export const useQueryLab = (labId?: string) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ['lab', labId],
    queryFn: async () => {
      const data = await get<Lab>(`/labs/lab/${labId}`);

      return data;
    },
    staleTime: config.staleTime,
  });
};
