import type { FC, ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAuthDomain, useRouter, useSearchParams } from "src/hooks";
import AuthorizedPage from "src/pages/401";

interface DomainGuardProps {
  children: ReactNode;
}

const STORAGE_KEY = "accessToken";

const checkToken = () => {
  return sessionStorage.getItem(STORAGE_KEY);
};

const saveToken = (data: any) => {
  sessionStorage.setItem(STORAGE_KEY, data);
};

export const DomainGuard: FC<DomainGuardProps> = ({ children }) => {
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const queryParams = useSearchParams();
  const token = queryParams.get("token") || "";
  const labKey = queryParams.get("labKey") || "";
  const userId = queryParams.get("userId") || "";
  const router = useRouter();
  const domainAuthService = useAuthDomain();
  const location = useLocation();
  const domain = document.referrer || "testing 4";

  const check = useCallback(async () => {
    setIsLoading(true);
    // Check for a saved token in localStorage
    const savedToken = checkToken();
    // If there's no saved token and no URL token, redirect to /404
    if (!savedToken && !token && !labKey) {
      setChecked(false);
    }

    if (savedToken) {
      setChecked(true);
    }

    // If there's a URL token, validate it and save it
    if (token) {
      // Validate the token here (you can replace this with your actual validation logic)
      try {
        await domainAuthService.mutateAsync(
          {
            domain,
            labKey,
            userId,
            labToken: token,
          },
          {
            onSuccess: (data) => {
              setChecked(true);
              saveToken(data.jwtToken); // Save the token in localStorage
              // Remove token from URL
              const currentUrl = window.location.href;
              const url = new URL(currentUrl);
              url.searchParams.delete("token");
              url.searchParams.delete("userId");
              url.searchParams.delete("labKey");
              router.replace(url.pathname + url.search);
            },
          },
        );
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error
          setChecked(false);
        } else {
          // Handle other types of errors
          console.log("Server internal error", error);
          setChecked(false);
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [router, domainAuthService]);

  useEffect(() => {
    if (location.pathname.startsWith("/dashboard")) {
      check();
    } else {
      setIsLoading(false);
      setChecked(true);
    }
  }, []);

  if (isLoading) {
    return null;
  }
  if (!checked) {
    return <AuthorizedPage />;
  }

  return <>{children}</>;
};
