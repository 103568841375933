import { useCallback } from 'react';
import { Model, setLicenseKey } from 'survey-core';
import { SurveyPDF } from 'survey-pdf';

import config from 'src/config';
import { convertSnakeCase } from 'src/utils/convert-snake-case';

function createSurveyPdfModel(surveyModel: Model) {
  const pdfWidth = surveyModel.pdfWidth ?? 210;
  const pdfHeight = surveyModel.pdfHeight ?? 297;

  // Register the license key
  setLicenseKey(config.surveyJSlicenseKey);

  const options = {
    fontSize: 14,
    margins: {
      left: 10,
      right: 10,
      top: 10,
      bot: 10,
    },
    format: [pdfWidth, pdfHeight],
  };

  const surveyPDF = new SurveyPDF(surveyModel.toJSON(), options);
  surveyPDF.data = surveyModel.data;

  return surveyPDF;
}

export function useDownloadPdf(title: string, surveyModel: Model) {
  return useCallback(async () => {
    const surveyPDF = createSurveyPdfModel(surveyModel);
    await surveyPDF.save(`${convertSnakeCase(title)}.pdf`);
  }, [surveyModel]);
}
