import { useQuery } from "@tanstack/react-query";
import qs from "qs";

import { useAxios } from "../use-axios";
import config from "../config";
import { SubmissionSearchState } from "src/pages/dashboard/form/submission-list";
import type { Submission } from "src/types/submission";

type GetSubmissionResponse = {
  data: Submission[];
  count: number;
  currentPage: number;
  totalPage: number;
};

export const useQuerySubmissions = (
  request: SubmissionSearchState,
  formId?: string,
) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["forms-query", request],
    queryFn: async () => {
      const params = {
        size: request.rowsPerPage,
        page: request.page,
        keyword: request.filters.name,
        token: request.token,
        formId,
      };

      const queryString = qs.stringify(params, { arrayFormat: "repeat" });
      const url = `/forms/submission/lists?${queryString}`;

      return await get<GetSubmissionResponse>(url);
    },
    staleTime: config.staleTime,
  });
};
