import type { FC, ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";

import { useRouter, useSearchParams, useValidateFormId } from "src/hooks";

interface FormIdGuardProps {
  children: ReactNode;
}

export const FormIdGuard: FC<FormIdGuardProps> = ({ children }) => {
  const [checked, setChecked] = useState(false);

  const queryParams = useSearchParams();
  const formId = queryParams.get("formId");
  const router = useRouter();
  const validateFormIdService = useValidateFormId();

  const check = useCallback(async () => {
    const isAuthenticated = await validateFormIdService.mutateAsync({
      formId: formId || "",
    });

    if (!isAuthenticated) {
      router.replace("/404");
    } else {
      setChecked(true);
    }
  }, [formId]);

  useEffect(() => {
    check();
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
};
