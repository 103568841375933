import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useRouter, useSearchParams, useValidateUserId } from 'src/hooks';

interface UserIdGuardGuardProps {
  children: ReactNode;
}

export const UserIdGuard: FC<UserIdGuardGuardProps> = ({ children }) => {
  const [checked, setChecked] = useState(false);

  const queryParams = useSearchParams();
  const userCode = queryParams.get('userCode');
  const router = useRouter();
  const validateUserIdService = useValidateUserId();

  const check = useCallback(async () => {
    const isAuthenticated = await validateUserIdService.mutateAsync({ userCode: userCode || '' });

    if (!isAuthenticated) {
      router.replace('/401');
    } else {
      setChecked(true);
    }
  }, [userCode]);

  useEffect(() => {
    check();
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
};
