import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";
import type { Domain } from "src/types/domain";

type GetDomainsRequest = {
  filters?: {
    query?: string;
  };
  page?: number;
  rowsPerPage?: number;
};

type GetLabsResponse = {
  data: Domain[];
  count: number;
  currentPage: number;
  totalPage: number;
};

export const useQueryDomains = (request: GetDomainsRequest) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["domain-query", request],
    queryFn: async () => {
      const params = {
        keyword: request.filters?.query,
        size: request.rowsPerPage,
        page: request.page,
      };

      return await get<GetLabsResponse>("/domain/lists", { params });
    },
    staleTime: config.staleTime,
  });
};
