import * as SurveyCore from 'survey-core';
import * as widgets from 'surveyjs-widgets';
import $ from 'jquery';

window['$'] = window['jQuery'] = $;
require('jquery-ui-dist/jquery-ui.js');

export const registerDatePicker = () => {
  widgets.jqueryuidatepicker(SurveyCore, $);
};
