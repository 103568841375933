export const paths = {
  index: "/",
  dashboard: {
    index: "/dashboard",
    labs: {
      index: "/dashboard/lab",
      create: "/dashboard/lab/create",
      edit: "/dashboard/lab/:labId/edit",
    },
    forms: {
      index: "/dashboard/form",
      create: "/dashboard/form/create",
      clone: "/dashboard/form/:formId/clone",
      edit: "/dashboard/form/:formId/edit",
      design: "/dashboard/form/:formId/design",
      preview: "/dashboard/form/:formId/preview",
      results: "/dashboard/form/:formId/results",
      submissions: "/dashboard/form/:formId/submissions/list",
      submissionPreview: "/dashboard/form/submissions/:submissionId/preview",
    },
    submissions: {
      index: "/dashboard/submission",
      questionsData: "/dashboard/submission/:formId/questionsData",
    },
    domain: {
      index: "/dashboard/domain",
      create: "/dashboard/domain/create",
    },
  },
  auth: {
    signIn: "/auth/signin",
  },
  submit: {
    index: "/form/fill",
    complete: "/submit/complete",
  },
};
