import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import type { ErrorType } from 'src/types/error';
import { useAxios } from '../use-axios';

type ValidateUserIdPayload = {
  userCode?: string | null;
};

export const useValidateUserId = () => {
  const { post } = useAxios();

  return useMutation<boolean, AxiosError<ErrorType>, ValidateUserIdPayload>(
    async (payload: ValidateUserIdPayload) =>
      await post<ValidateUserIdPayload, boolean>('/auth/user', payload),
  );
};
