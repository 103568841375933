import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import type { Submission } from "src/types/submission";
import config from "../config";

export const useQuerySubmission = (submissionId?: string | null) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["submission", submissionId],
    queryFn: async () => {
      return await get<Submission>(`/forms/submission/${submissionId}`);
    },
    staleTime: config.staleTime,
  });
};
