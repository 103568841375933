import React, { type FC } from "react";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import * as SurveyTheme from "survey-core/themes";

import { registerDivider } from "src/components/formBuilder/fc-divider";
import { registerDatePicker } from "src/components/formBuilder/fc-datepicker";
import { registerBarRating } from "src/components/formBuilder/fc-bar-rating";
import { useRouter, useSearchParams, useSubmitForm } from "src/hooks";
import { paths } from "src/paths";

interface FormSubmissionProps {
  formJsonData: string;
}

// Register the custom toolbar element
registerDivider();
registerDatePicker();
registerBarRating();

export const FormSubmission: FC<FormSubmissionProps> = ({ formJsonData }) => {
  const survey: any = new Model(formJsonData);
  survey.applyTheme(SurveyTheme.FlatLight);

  const queryParams = useSearchParams();
  const userCode = queryParams.get("userCode");
  const formId = queryParams.get("formId");
  const submitFormService = useSubmitForm();
  const router = useRouter();

  survey.onComplete.add(async (sender: any) => {
    await submitFormService.mutateAsync(
      {
        formId: formId || "",
        userCode: userCode || "",
        formData: JSON.stringify(sender.data, null, 3),
      },
      {
        onSuccess: () => {
          router.replace(paths.submit.complete);
        },
      },
    );
  });

  return <Survey model={survey} />;
};
