import { useQuery } from '@tanstack/react-query';
import qs from 'qs';

import { useAxios } from '../use-axios';
import config from '../config';
import { FormsSearchState } from 'src/pages/dashboard/form/list';
import type { Form } from 'src/types/form';

type GetFormsResponse = {
  data: Form[];
  count: number;
  currentPage: number;
  totalPage: number;
};

export const useQueryForms = (request: FormsSearchState, isSubmissionPage: boolean = false) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ['forms-query', request],
    queryFn: async () => {
      const params = {
        size: request.rowsPerPage,
        page: request.page,
        keyword: request.filters.name,
        category: request.filters.category,
        status: request.filters.status,
        token: request.token,
        isSubmissionPage,
      };

      const queryString = qs.stringify(params, { arrayFormat: 'repeat' });
      const url = `/forms/lists?${queryString}`;

      const data = await get<GetFormsResponse>(url);

      return data;
    },
    staleTime: config.staleTime,
  });
};
