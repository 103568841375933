import { useQuery } from "@tanstack/react-query";

import { useAxios } from "../use-axios";
import config from "../config";

type QueryPagesRequest = {
  formId?: string | null;
};

type QueryPagesResponse = {
  pageName: string;
  pageTitle: string;
}[];

export const useQueryPages = (params: QueryPagesRequest) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ["submission-pages", params.formId],
    queryFn: async () => {
      return await get<QueryPagesResponse>(`/data/question/pages`, { params });
    },
    staleTime: config.staleTime,
  });
};
