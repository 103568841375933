import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import type { Lab } from 'src/types/lab';
import type { ErrorType } from 'src/types/error';
import { useAxios } from '../use-axios';

type CreateLabsRequestPayload = {
  labName: string;
  email: string;
  description: string;
};

export const useCreateLab = () => {
  const { post } = useAxios();

  return useMutation<Lab, AxiosError<ErrorType>, CreateLabsRequestPayload>(
    async (payload: CreateLabsRequestPayload) =>
      await post<CreateLabsRequestPayload, Lab>('/labs/create', payload),
  );
};
