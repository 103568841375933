import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAxios } from '../use-axios';
import type { ErrorType } from 'src/types/error';

type RefreshLabKeyRequest = {
  ids: string[];
};

type RefreshLabKeyResponse = {};

export const useRefreshKey = () => {
  const { post } = useAxios();

  return useMutation<RefreshLabKeyResponse, AxiosError<ErrorType>, RefreshLabKeyRequest>(
    async (payload: RefreshLabKeyRequest) =>
      await post<RefreshLabKeyRequest, RefreshLabKeyResponse>('/labs/refresh', payload),
  );
};
