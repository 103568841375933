import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import type { ErrorType } from "src/types/error";
import { useAxios } from "../use-axios";
import { Form } from "src/types/form";

type CloneFormsRequestPayload = {
  formName: string;
  formCategory: string;
  description: string;
  labId?: string;
  formId?: string;
};

export const useCloneForm = () => {
  const { post } = useAxios();

  return useMutation<Form, AxiosError<ErrorType>, CloneFormsRequestPayload>(
    async (payload: CloneFormsRequestPayload) =>
      await post<CloneFormsRequestPayload, Form>("/forms/clone", payload),
  );
};
